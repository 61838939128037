import { useMemo, useState } from 'react';
import cn from 'classnames';

import MediaChannelsBlackPc from '../../../common/assets/images/media-channels-black-pc.png';
import MediaChannelsBlackM from '../../../common/assets/images/media-channels-black-m.png';
import useScrollToTop from '../../../common/hook/useScrollToTop';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../util/date';

const NewsPage = () => {
  const [langs, setLangs] = useState(['kr', 'en']);
  const { t, i18n } = useTranslation();

  useScrollToTop();

  const data = useMemo(() => {
    const d = [
      {
        title: t('news34'),
        date: '2019-12-27',
        media: 'Wired',
        lang: 'en',
        link: 'https://www.wired.kr/news/articleView.html?idxno=643',
      },
      {
        title: t('news33'),
        date: '2021-01-25',
        media: 'CNN',
        lang: 'en',
        link: 'https://edition.cnn.com/2021/01/25/asia/south-korea-kim-kwang-seok-ai-dst-hnk-intl/index.html',
      },
      {
        title: t('news32'),
        date: '2021-01-27',
        media: 'Asia Today',
        lang: 'kr',
        link: 'https://www.asiatoday.co.kr/view.php?key=20210127010016018',
      },
      {
        title: t('news30'),
        date: '2021-02-02',
        media: 'The Dong-a Ilbo',
        lang: 'kr',
        link: 'https://www.donga.com/news/article/all/20210202/105229542/1',
      },
      {
        title: t('news29'),
        date: '2021-02-25',
        media: 'Music Business Worldwide',
        lang: 'en',
        link: 'https://www.musicbusinessworldwide.com/big-hit-invests-3-6m-in-supertone-an-ai-firm-that-just-cloned-a-dead-superstars-voice/',
      },
      {
        title: t('news28'),
        date: '2021-02-25',
        media: 'Platum',
        lang: 'kr',
        link: 'https://platum.kr/archives/158573',
      },
      {
        title: t('news27'),
        date: '2021-02-25',
        media: 'Yonhap News Agency',
        lang: 'kr',
        link: 'https://www.yna.co.kr/view/AKR20210225058700005?input=1195m',
      },
      {
        title: t('news26'),
        date: '2021-03-19',
        media: 'AI Times',
        lang: 'kr',
        link: 'http://www.aitimes.com/news/articleView.html?idxno=137417',
      },
      {
        title: t('news25'),
        date: '2021-10-19',
        media: 'The Korea Economic Daily',
        lang: 'kr',
        link: 'https://www.hankyung.com/it/article/2021101982321',
      },
      {
        title: t('news24'),
        date: '2021-12-02',
        media: 'News1',
        lang: 'kr',
        link: 'https://www.news1.kr/articles/4511648',
      },
      {
        title: t('news22'),
        date: '2022-02-04',
        media: 'Huffington Post',
        lang: 'kr',
        link: 'https://www.huffingtonpost.kr/news/articleView.html?idxno=116505',
      },
      {
        title: t('news21'),
        date: '2022-05-19',
        media: 'The JoongAng',
        lang: 'kr',
        link: 'https://www.joongang.co.kr/article/25072486',
      },
      {
        title: t('news20'),
        date: '2022-07-14',
        media: 'Yonhap News Agency',
        lang: 'kr',
        link: 'https://www.yna.co.kr/view/AKR20220714045300017?input=1195m',
      },
      {
        title: t('news19'),
        date: '2023-01-02',
        media: 'Maeil Business Newspaper',
        lang: 'kr',
        link: 'https://www.mk.co.kr/news/culture/10588818',
      },
      {
        title: t('news18'),
        date: '2023-01-31',
        media: 'Yonhap News Agency',
        lang: 'en',
        link: 'https://en.yna.co.kr/view/AEN20230131003300315',
      },
      {
        title: t('news17'),
        date: '2023-01-31',
        media: 'Cnet Korea',
        lang: 'kr',
        link: 'https://www.cnet.co.kr/view/?no=20230131110518',
      },
      {
        title: t('news1'),
        date: '2023-06-19',
        media: 'Mashable',
        lang: 'en',
        link: 'https://mashable.com/article/hybe-ai-artist-midnatt-lee-kyogu-interview',
      },
      {
        title: t('news2'),
        date: '2023-07-17',
        media: '전자신문',
        lang: 'kr',
        link: 'https://www.etnews.com/20230717000291',
      },
      {
        title: t('news3'),
        date: '2023-07-18',
        media: '파이낸셜 뉴스',
        lang: 'kr',
        link: 'https://www.fnnews.com/news/202307180821164428',
      },
      {
        title: t('news4'),
        date: '2023-08-30',
        media: '한국경제 TV',
        lang: 'kr',
        link: 'https://www.wowtv.co.kr/NewsCenter/News/Read?articleId=A202308300250&t=NN',
      },
      {
        title: t('news5'),
        date: '2023-09-06',
        media: 'HELLOKPOP',
        lang: 'en',
        link: 'https://www.hellokpop.com/news/supertone-collaborates-with-netflix-series-mask-girl-to-pioneer-voice-technology/',
      },
      {
        title: t('news6'),
        date: '2023-09-14',
        media: 'ZDNet Korea',
        lang: 'kr',
        link: 'https://zdnet.co.kr/view/?no=20230914153002',
      },
      {
        title: t('news7'),
        date: '2023-09-27',
        media: '한겨레',
        lang: 'kr',
        link: 'https://www.hani.co.kr/arti/economy/it/1110344.html',
      },
      {
        title: t('news8'),
        date: '2023-09-28',
        media: '서울신문',
        lang: 'kr',
        link: 'https://www.seoul.co.kr/news/newsView.php?id=20230928500025',
      },
      {
        title: t('news9'),
        date: '2023-10-02',
        media: '바이라인네트워크',
        lang: 'kr',
        link: 'https://byline.network/2023/10/1002/',
      },
      {
        title: t('news10'),
        date: '2023-10-07',
        media: '이데일리',
        lang: 'kr',
        link: 'https://www.edaily.co.kr/news/read?newsId=01088966635770624&mediaCodeNo=258',
      },
      {
        title: t('news11'),
        date: '2023-10-07',
        media: '연합뉴스',
        lang: 'kr',
        link: 'https://www.yna.co.kr/view/AKR20231030032500005',
      },
      {
        title: t('news12'),
        date: '2023-10-07',
        media: '이데일리',
        lang: 'kr',
        link: 'https://www.edaily.co.kr/news/read?newsId=01082406635770624&mediaCodeNo=258',
      },
      {
        title: t('news13'),
        date: '2023-10-07',
        media: '이데일리',
        lang: 'kr',
        link: 'https://www.edaily.co.kr/news/read?newsId=01085686635770624&mediaCodeNo=258',
      },
      {
        title: t('news14'),
        date: '2023-11-19',
        media: '디지털투데이',
        lang: 'kr',
        link: 'https://www.digitaltoday.co.kr/news/articleView.html?idxno=494904',
      },
      {
        title: t('news15'),
        date: '2024-01-04',
        media: 'SBS 8 뉴스',
        lang: 'kr',
        link: 'https://news.sbs.co.kr/news/endPage.do?news_id=N1007484375&plink=COPYPASTE&cooper=SBSNEWSEND',
      },
      {
        title: t('news16'),
        date: '2024-01-14',
        media: '중앙일보',
        lang: 'kr',
        link: 'https://www.joongang.co.kr/article/25221764#home',
      },
    ];

    return d.sort((a, b) => (a.date > b.date ? -1 : 1));
  }, [t]);

  const onLangsChange = (langs: string[]) => () => {
    setLangs(langs);
  };

  const isKr = langs.includes('kr');
  const isEn = langs.includes('en');
  const isAll = isKr && isEn;

  return (
    <section className="news-page">
      <article>
        <div className="title no-p">
          <h1>News</h1>
        </div>

        <picture>
          <source media="(min-width: 600px)" srcSet={MediaChannelsBlackPc} />
          <img src={MediaChannelsBlackM} alt="" />
        </picture>

        <div className="filters">
          <button
            onClick={onLangsChange(['kr', 'en'])}
            className={cn('filter', 'lang-all', isAll && 'checked')}
          >
            ALL
          </button>
          <button
            onClick={onLangsChange(['kr'])}
            className={cn('filter', 'lang-kr', !isAll && isKr && 'checked')}
          >
            KR
          </button>
          <button
            onClick={onLangsChange(['en'])}
            className={cn('filter', 'lang-en', !isAll && isEn && 'checked')}
          >
            EN
          </button>
        </div>

        <ul className="news-container">
          {data.map((item, index) => (
            <li
              key={index}
              className={cn('news', !langs.includes(item.lang) && 'hidden')}
            >
              <div className="media-title">
                <h3>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    {item.title}
                  </a>
                </h3>
                <p>
                  {formatDate(item.date, i18n.language)} / {item.media}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </article>
    </section>
  );
};

export default NewsPage;
