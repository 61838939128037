// Convert date to localized string based on the given locale.
export const formatDate = (dateStr: string, locale: string) => {
  const date = new Date(dateStr);

  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
