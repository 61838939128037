import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutV3 from '../common/v3/Layout';
import MainPage from './main/MainPage';
import MediaPage from './media/MediaPage';
import NewsPage from './media/news/NewsPage';
import PeoplePage from './people/PeoplePage';
import DemoPage from './demo/DemoPage';
import AgreementPage from './demo/AgreementPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutV3 />}>
          <Route index element={<MainPage />} />
          <Route path="/media" element={<MediaPage />} />
          <Route path="/media/news" element={<NewsPage />} />
          <Route path="/people" element={<PeoplePage />} />
        </Route>

        <Route path="/demo" element={<DemoPage />} />
        <Route path="/demo/agreement" element={<AgreementPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
