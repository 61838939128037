// Set an item in local storage with an optional time to live (tts) in seconds.
export const setItem = (key: string, value: any, tts?: number) => {
  const obj = {
    value: JSON.stringify(value),
    expires: tts ? Date.now() + tts * 1000 : undefined,
  };

  const str = JSON.stringify(obj);

  window.localStorage.setItem(key, str);
};

// Get an item from local storage. If the item has expired, it will be removed.
export const getItem = (key: string) => {
  const str = window.localStorage.getItem(key);

  if (!str) {
    return null;
  }

  const obj = JSON.parse(str);

  if (obj.expires && obj.expires < Date.now()) {
    window.localStorage.removeItem(key);
    return null;
  }

  return obj.value;
};
