import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';

import NewsThumbnail01 from '../../../common/assets/images/thumbnails/BNR_News_01.png';
import NewsThumbnail02 from '../../../common/assets/images/thumbnails/BNR_News_02.jpg';
import NewsThumbnail03 from '../../../common/assets/images/thumbnails/BNR_News_03.png';
import NewsThumbnail04 from '../../../common/assets/images/thumbnails/BNR_News_04.jpg';
import NewsThumbnail05 from '../../../common/assets/images/thumbnails/BNR_News_05.jpg';
import NewsThumbnail06 from '../../../common/assets/images/thumbnails/BNR_News_06.jpg';
import NewsThumbnail07 from '../../../common/assets/images/thumbnails/BNR_News_07.jpg';
import NewsThumbnail08 from '../../../common/assets/images/thumbnails/BNR_News_08.png';
import { formatDate } from '../../../util/date';

const News = () => {
  const { t, i18n } = useTranslation();

  const data = useMemo(() => {
    return [
      {
        thumbnail: NewsThumbnail01,
        title: t('media-news8'),
        date: '2023-01-31',
        media: 'CNET Korea',
        link: 'https://www.cnet.co.kr/view/?no=20230131110518',
      },
      {
        thumbnail: NewsThumbnail02,
        title: t('media-news1'),
        date: '2023-11-19',
        media: '디지털투데이',
        link: 'https://www.digitaltoday.co.kr/news/articleView.html?idxno=494904',
      },
      {
        thumbnail: NewsThumbnail03,
        title: t('media-news6'),
        date: '2021-01-01',
        media: '매일경제',
        link: 'https://www.mk.co.kr/news/culture/10588818',
      },
      {
        thumbnail: NewsThumbnail04,
        title: t('media-news4'),
        date: '2023-08-30',
        media: '한국경제 TV',
        link: 'https://www.wowtv.co.kr/NewsCenter/News/Read?articleId=A202308300250&t=NN',
      },
      {
        thumbnail: NewsThumbnail05,
        title: t('media-news2'),
        date: '2023-10-07',
        media: '이데일리',
        link: 'https://www.edaily.co.kr/news/read?newsId=01085686635770624&mediaCodeNo=258',
      },
      {
        thumbnail: NewsThumbnail06,
        title: t('media-news3'),
        date: '2023-09-14',
        media: 'ZDNet Korea',
        link: 'https://zdnet.co.kr/view/?no=20230914153002',
      },
      {
        thumbnail: NewsThumbnail07,
        title: t('media-news5'),
        date: '2023-07-17',
        media: '전자신문',
        link: 'https://www.etnews.com/20230717000291',
      },
      {
        thumbnail: NewsThumbnail08,
        title: t('media-news7'),
        date: '2021-01-25',
        media: 'CNN',
        link: 'https://edition.cnn.com/2021/01/25/asia/south-korea-kim-kwang-seok-ai-dst-hnk-intl/index.html',
      },
    ];
  }, [t]);

  return (
    <section className="news">
      <article>
        <div className="title no-p">
          <h1>News</h1>
        </div>

        <div className="news-container">
          <ul className="news-list">
            {data.map((item, index) => (
              <li key={index}>
                <div className="media-title">
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <h3>{item.title}</h3>
                  </a>

                  <p>
                    {formatDate(item.date, i18n.language)} / {item.media}
                  </p>
                </div>
                <div className="news-img">
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img src={item.thumbnail} alt="" />
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <Link className="media-button" to="/media/news">
          Read More News
        </Link>
      </article>
    </section>
  );
};

export default News;
