import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import MoreBlack from '../../../common/assets/images/icons/more-black.svg';
import MoreBlackReverse from '../../../common/assets/images/icons/more-black-reverse.svg';

const UseCases = () => {
  const { t } = useTranslation();
  const [isMoreContentVisible, setIsMoreContentVisible] = useState(false);

  const moreBtn = isMoreContentVisible ? MoreBlackReverse : MoreBlack;

  return (
    <>
      {/* Mobile */}
      <section className="usecases only-m">
        <article>
          <div className="title">
            <h1>Use Cases</h1>
          </div>

          {/* 접히지 않는 부분 */}
          <h2>Music</h2>
          <h3>Song, Revival, Live Performance</h3>
          <p>{t('use1')}</p>

          <h2>Video</h2>
          <h3>Movie, OTT, Broadcast, Youtube</h3>
          <p>{t('use2')}</p>

          {/* 접히는 부분 */}
          <div className={cn('more', !isMoreContentVisible && 'hidden')}>
            <h2>Game</h2>
            <h3>Character Design, Voice Dubbing, Universe</h3>
            <p>{t('use3')}</p>

            <h2>Voice Identity</h2>
            <h3>Company Identity, Voice Interface, PR/Marketing</h3>
            <p>{t('use4')}</p>
          </div>

          <button
            className="more-btn"
            onClick={() => setIsMoreContentVisible(!isMoreContentVisible)}
          >
            <img src={moreBtn} alt="" width="34px" />
          </button>
        </article>
      </section>

      {/* Desktop */}
      <section className="usecases only-pc">
        <article>
          <div className="title">
            <h1>Use Cases</h1>
          </div>

          {/* 접히지 않는 부분 */}
          <h2>Music</h2>
          <h3>Song, Revival, Live Performance</h3>
          <p>{t('use1')}</p>

          <h2>Video</h2>
          <h3>Movie, OTT, Broadcast, Youtube</h3>
          <p>{t('use2')}</p>

          <h2>Game</h2>
          <h3>Character Design, Voice Dubbing, Universe</h3>
          <p>{t('use3')}</p>

          {/* 접히는 부분 */}
          <div className={cn('more', !isMoreContentVisible && 'hidden')}>
            <h2>Voice Identity</h2>
            <h3>Company Identity, Voice Interface, PR/Marketing</h3>
            <p>{t('use4')}</p>
          </div>

          <button
            className="more-btn"
            onClick={() => setIsMoreContentVisible(!isMoreContentVisible)}
          >
            <img src={moreBtn} alt="" width="34px" />
          </button>
        </article>
      </section>
    </>
  );
};

export default UseCases;
