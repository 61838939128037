import useScrollToTop from '../../common/hook/useScrollToTop';
import History from './partial/History';
import Join from './partial/Join';
import People from './partial/People';
import Vision from './partial/Vision';

const PeoplePage = () => {
  useScrollToTop();

  return (
    <>
      {/* People */}
      <People />

      {/* Vision */}
      <Vision />

      {/* History */}
      <History />

      {/* Join Our Team */}
      <Join />
    </>
  );
};

export default PeoplePage;
