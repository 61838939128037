import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import MoreBlack from '../../../common/assets/images/icons/more-black.svg';
import MoreBlackReverse from '../../../common/assets/images/icons/more-black-reverse.svg';
import { useState } from 'react';

const History = () => {
  const { t } = useTranslation();
  const [isMoreContentVisible, setIsMoreContentVisible] = useState(false);

  const moreBtn = isMoreContentVisible ? MoreBlackReverse : MoreBlack;

  return (
    <>
      {/* Mobile */}
      <section className="history only-m">
        <article>
          <div className="title no-p">
            <h1>History</h1>
          </div>
          <div className="table">
            {/* 접히지 않는 부분 */}
            <div className="column">
              <div>2023</div>
              <div>{t('history14')}</div>
            </div>
            <div className="column">
              <div>2023</div>
              <div>{t('history13')}</div>
            </div>
            <div className="column">
              <div>2023</div>
              <div>{t('history12')}</div>
            </div>
            <div className="column">
              <div>2023</div>
              <div>{t('history11')}</div>
            </div>

            {/* 접히는 부분 */}
            <div className={cn('more', !isMoreContentVisible && 'hidden')}>
              <div className="column">
                <div>2023</div>
                <div>{t('history10')}</div>
              </div>
              <div className="column">
                <div>2022</div>
                <div>{t('history9')}</div>
              </div>
              <div className="column">
                <div>2022</div>
                <div>{t('history8')}</div>
              </div>
              <div className="column">
                <div>2022</div>
                <div>{t('history7')}</div>
              </div>
              <div className="column">
                <div>2021</div>
                <div>{t('history6')}</div>
              </div>
              <div className="column">
                <div>2021</div>
                <div>{t('history5')}</div>
              </div>
              <div className="column">
                <div>2021</div>
                <div>{t('history4')}</div>
              </div>
              <div className="column">
                <div>2020</div>
                <div>{t('history3')}</div>
              </div>
              <div className="column">
                <div>2020</div>
                <div>{t('history2')}</div>
              </div>
              <div className="column">
                <div>2020</div>
                <div>{t('history1')}</div>
              </div>
            </div>
          </div>
          <button
            className="more-btn"
            onClick={() => setIsMoreContentVisible(!isMoreContentVisible)}
          >
            <img src={moreBtn} alt="" width="34px" />
          </button>
        </article>
      </section>

      {/* Desktop */}
      <section className="history only-pc">
        <article>
          <div className="title no-p">
            <h1>History</h1>
          </div>

          <div className="history-inner-wrap">
            {/* Left column */}
            <div className="history-inner left">
              <div className="column">
                <div>2023</div>
                <div>{t('history14')}</div>
              </div>
              <div className="column">
                <div>2023</div>
                <div>{t('history13')}</div>
              </div>
              <div className="column">
                <div>2023</div>
                <div>{t('history12')}</div>
              </div>
              <div className="column">
                <div>2023</div>
                <div>{t('history11')}</div>
              </div>
              <div className="column">
                <div>2023</div>
                <div>{t('history10')}</div>
              </div>
              <div className="column">
                <div>2022</div>
                <div>{t('history9')}</div>
              </div>
              <div className="column">
                <div>2022</div>
                <div>{t('history8')}</div>
              </div>
            </div>

            {/* Right column */}
            <div className="history-inner right">
              <div className="column">
                <div>2022</div>
                <div>{t('history7')}</div>
              </div>
              <div className="column">
                <div>2021</div>
                <div>{t('history6')}</div>
              </div>
              <div className="column">
                <div>2021</div>
                <div>{t('history5')}</div>
              </div>
              <div className="column">
                <div>2021</div>
                <div>{t('history4')}</div>
              </div>
              <div className="column">
                <div>2020</div>
                <div>{t('history3')}</div>
              </div>
              <div className="column">
                <div>2020</div>
                <div>{t('history2')}</div>
              </div>
              <div className="column">
                <div>2020</div>
                <div>{t('history1')}</div>
              </div>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default History;
