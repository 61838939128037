import { Trans, useTranslation } from 'react-i18next';
import JoinDiagram from '../../../common/assets/images/people-join.svg';

const Join = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Mobile */}
      <section className="join only-m">
        <article>
          <div className="title">
            <h1>Join Our Team</h1>
          </div>

          <img src={JoinDiagram} alt="" className="join-diagram" />

          <p className="p-1">{t('join1')}</p>
          <p className="p-2">{t('join2')}</p>
          <img
            src="/assets/images/people-join-cards.png"
            alt=""
            className="join-cards"
            width="100%"
          />
          <a
            href="https://supertone.notion.site/Supertone-Career-dde2d59d66fd4cf5b5420ea02c23b751?pvs=4"
            target="_blank"
            rel="noreferrer"
          >
            More Information
          </a>
          <p className="divider"></p>
          <p className="p-3">{t('join3')}</p>
          <a href="mailto:join@supertone.ai">join@supertone.ai</a>
        </article>
      </section>

      {/* Desktop */}
      <section className="join only-pc">
        <article>
          <div className="title no-p">
            <h1>Join Our Team</h1>
          </div>

          <div className="join-inner-wrap">
            <div className="join-inner left">
              <p className="p-1">
                <Trans i18nKey="join1" />
              </p>
              <p className="p-2">
                <Trans i18nKey="join2" />
              </p>
            </div>

            <div className="join-inner right">
              <img src={JoinDiagram} alt="" width="100%" />
            </div>
          </div>

          <div className="join-inner-bottom">
            <a
              href="https://supertone.notion.site/Supertone-Career-dde2d59d66fd4cf5b5420ea02c23b751?pvs=4"
              target="_blank"
              rel="noreferrer"
              className="info-btn"
            >
              More Information
            </a>
            <div className="mail-text">{t('join3')}</div>
            <a href="mailto:join@supertone.ai" className="mail-btn">
              join@supertone.ai
            </a>
          </div>
        </article>
      </section>
    </>
  );
};

export default Join;
