import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Scroll to the element according to the hash in the URL.
const HashElementScroller = () => {
  const location = useLocation();

  useEffect(() => {
    const onLoad = () => {
      const hash = location.hash;
      const elementId = hash?.substring(1);
      const element = elementId ? document.getElementById(elementId) : null;

      if (!element) return;

      element.scrollIntoView();
    };

    // 'load' events must be used here in order to have the correct scroll position.
    window.addEventListener('load', onLoad);

    return () => {
      window.removeEventListener('load', onLoad);
    };
  }, [location.hash]);

  return null;
};

export default HashElementScroller;
