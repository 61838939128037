import { useTranslation } from 'react-i18next';
import GraphicVision from '../../../common/assets/images/people-graphic-vision.png';

const Vision = () => {
  const { t } = useTranslation();

  return (
    <section className="vision">
      <article>
        <div className="title">
          <h1>Vision</h1>
        </div>

        <div className="vision-inner-wrap">
          {/* Left column */}
          <div>
            <img src={GraphicVision} alt="" width="100%" />
          </div>

          {/* Right column */}
          <div>
            <h2>
              Intelligent Audio
              <br />
              for Creativity
            </h2>
            <p className="p-1">{t('vision1')}</p>
            <p className="p-2">{t('vision2')}</p>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Vision;
