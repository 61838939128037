import { useTranslation } from 'react-i18next';

import GraphicEthics from '../../../common/assets/images/main-graphic-ethics.png';

const EthicalAi = () => {
  const { t } = useTranslation();

  return (
    <section className="ethics">
      <article>
        <div className="title">
          <h1>Ethical AI</h1>
        </div>

        <div className="ethics-inner-wrap">
          {/* Left column */}
          <div>
            <img src={GraphicEthics} alt="" width="100%" />
          </div>

          {/* Right column */}
          <div>
            <p>{t('ethic1')}</p>

            <ul>
              <li>
                <h2>{t('ethic2')}</h2>
                <p>{t('ethic3')}</p>
              </li>
              <li>
                <h2>{t('ethic4')}</h2>
                <p>{t('ethic5')}</p>
              </li>
              <li>
                <h2>{t('ethic6')}</h2>
                <p>{t('ethic7')}</p>
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>
  );
};

export default EthicalAi;
