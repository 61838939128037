import { FormEvent, useEffect, useState } from 'react';
import { setItem } from '../../common/localStorage';
import { useLocation, useNavigate } from 'react-router-dom';

export const DEMO_LICENSE_AGREED_KEY = 'supertone.studio.demo.licenseAgreed';

const AgreementPage = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [password, setPassword] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  // Add 'demo' class to the body.
  useEffect(() => {
    document.body.classList.add('demo');

    return () => {
      document.body.classList.remove('demo');
    };
  }, []);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!isAgreed) return;
    if (password !== 'superdemo') return;

    // TTS is 24hrs.
    setItem(DEMO_LICENSE_AGREED_KEY, true, 24 * 60 * 60);

    // Keep the original hash.
    navigate('/demo' + location.hash, {
      replace: true,
    });
  };

  return (
    <section id="entrance" className="entrance">
      <article>
        <form onSubmit={onSubmit}>
          <div className="entrance-content">
            <div className="agreement-wrap">
              <div className="agreement ko">
                본 내용은 수퍼톤의 기술력을 보여드리기 위해 기존 작품들을
                활용하여 만든 데모들입니다. 반드시 내부용으로만 시청하실 것을
                당부드립니다. 수퍼톤의 동의 없이 외부로 유포/재가공 할 경우,
                법적인 책임 및 피해보상을 요구할 수 있습니다.
              </div>
              <div className="agreement en">
                The following materials are demos created using commercially
                available media to exhibit Supertone’s technology. Please be
                advised that they are meant for internal viewing purposes only.
                In case of the materials being distributed, modified, or
                tampered without Supertone’s explicit consent, we may seek,
                obtain, and enforce injunctive relief and/or compensation.
              </div>
            </div>
            <div className="input-wrap">
              <div className="checkbox-wrap">
                <input
                  type="checkbox"
                  id="entrance-checkbox"
                  checked={isAgreed}
                  onChange={() => setIsAgreed(!isAgreed)}
                />
                <label htmlFor="entrance-checkbox" className="checkbox-text">
                  I have read and understood the agreement above.
                </label>
              </div>
              <div className="password-wrap">
                <p className="password-text">Password</p>
                <input
                  type="password"
                  id="p1a2s3s4w5o6r7d"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button id="enter" type="submit">
                  Enter
                </button>
              </div>
            </div>
          </div>
        </form>
      </article>
    </section>
  );
};

export default AgreementPage;
