import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import MediaThumbnail01 from '../../../common/assets/images/thumbnails/BNR_Media_01.jpg';
import MediaThumbnail02 from '../../../common/assets/images/thumbnails/BNR_Media_02.jpg';
import MediaThumbnail03 from '../../../common/assets/images/thumbnails/BNR_Media_03.jpg';
import MediaThumbnail04 from '../../../common/assets/images/thumbnails/BNR_Media_041.jpg';
import MediaThumbnail05 from '../../../common/assets/images/thumbnails/BNR_Media_05.png';
import MediaThumbnail06 from '../../../common/assets/images/thumbnails/BNR_Media_06.png';
import { formatDate } from '../../../util/date';

const Media = () => {
  const { t, i18n } = useTranslation();

  const data = useMemo(() => {
    return [
      {
        thumbnail: MediaThumbnail01,
        title: t('media1'),
        description: t('media1-description'),
        date: '2023-11-02',
        link: 'https://youtu.be/MwjOhPBQPUE?si=KejZVIl08rosiB3U',
      },
      {
        thumbnail: MediaThumbnail02,
        title: t('media2'),
        description: t('media2-description'),
        date: '2023-05-16',
        link: 'https://youtu.be/-UIYBvd4kUU?si=40L5YFZVyWd160Wh',
      },
      {
        thumbnail: MediaThumbnail03,
        title: t('media3'),
        description: t('media3-description'),
        date: '2023-12-19',
        link: 'https://youtu.be/mGj9Li0mm_Y?si=bo4qo6AbbPZuR1ai',
      },
      {
        thumbnail: MediaThumbnail04,
        title: t('media4'),
        description: t('media4-description'),
        date: '2024-01-19',
        link: 'https://news.sbs.co.kr/news/endPage.do?news_id=N1007484375',
      },
      {
        thumbnail: MediaThumbnail05,
        title: t('media5'),
        description: t('media5-description'),
        date: '2023-01-09',
        link: 'https://youtu.be/L7-UFtcFlDk?si=SZ532hnM5Sg2Ehxo',
      },
      {
        thumbnail: MediaThumbnail06,
        title: t('media6'),
        description: t('media6-description'),
        date: '2021-01-29',
        link: 'https://www.youtube.com/watch?v=WwUHX7oD_ak&list=PLaTNMdJ9DIh4WUPDYxeNfCoA4yESMfLK5&index=2',
      },
    ];
  }, [t]);

  return (
    <section className="media">
      <article>
        <div className="title no-p">
          <h1>Media</h1>
        </div>

        {/* Media List */}
        <ul>
          {data.map((item, index) => (
            <li key={index}>
              <div className="main-video">
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img src={item.thumbnail} alt="" />
                </a>
              </div>
              <div className="media-title">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <p>{formatDate(item.date, i18n.language)}</p>
              </div>
            </li>
          ))}
        </ul>

        <a
          className="media-button"
          href="https://www.youtube.com/playlist?list=PLaTNMdJ9DIh4WUPDYxeNfCoA4yESMfLK5"
          target="_blank"
          rel="noreferrer"
        >
          Watch More Videos
        </a>
      </article>
    </section>
  );
};

export default Media;
