import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '../common/assets/locales/i18n';
import { SUPAuthProvider, SupertoneApps } from '@supertone-inc/auth-sdk-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <SUPAuthProvider
      supertoneApp={SupertoneApps.Website}
      authorizationParams={{
        redirect_uri: window.location.origin + window.location.pathname,
      }}
      isDevelopment={true}
    >
      <App />
    </SUPAuthProvider>
  </React.StrictMode>
);
