import { useEffect, useState } from 'react';
import $ from 'jquery';
import cn from 'classnames';

import AdversariallyTrained from '../../../common/assets/images/publications-n-awards/publications_adversarially_trained.png';
import DisentanglingTimbre from '../../../common/assets/images/publications-n-awards/publications_disentangling_timbre.png';
import Icassp from '../../../common/assets/images/publications-n-awards/publications_icassp.png';
import NeuralAnalysis from '../../../common/assets/images/publications-n-awards/publications_neural_analysis.png';
import Nuvo from '../../../common/assets/images/publications-n-awards/publications_nuvo.png';
import RealtimeDenoising from '../../../common/assets/images/publications-n-awards/publications_realtime_denoising.png';

import MoreBlack from '../../../common/assets/images/icons/more-black.svg';
import MoreBlackReverse from '../../../common/assets/images/icons/more-black-reverse.svg';

const publicationPreviews = {
  nuvo: Nuvo,
  neural_analysis: NeuralAnalysis,
  realtime_denoising: RealtimeDenoising,
  icassp: Icassp,
  disentangling_timbre: DisentanglingTimbre,
  adversarially_trained: AdversariallyTrained,
};

const PublicationsAwards = () => {
  const [isMoreContentVisible, setIsMoreContentVisible] = useState(false);

  useEffect(() => {
    $('.pub-n-awards .pna-inner li')
      .off()
      .on('pointerenter', function () {
        const pnaImg = $(this).attr('id') as keyof typeof publicationPreviews;
        if (!pnaImg) return;

        $('.pub-n-awards .pna-img img').attr(
          'src',
          publicationPreviews[pnaImg]
        );
      });
  }, []);

  const moreBtn = isMoreContentVisible ? MoreBlackReverse : MoreBlack;

  return (
    <>
      {/* Mobile */}
      <section className="pub-n-awards only-m">
        <article>
          <div className="title no-p">
            <h1>Publications & Awards</h1>
          </div>

          <ul className="en">
            {/* 접히지 않는 부분 */}
            <li>
              <img src="" alt="" width="100%" />
              <h2>
                CES 2022 Innovation Awards Honoree: Software & Mobile Apps,
                2022.
              </h2>
              <a
                href="//www.ces.tech/Innovation-Awards/Honorees/2022/Honorees/N/NUVO.aspx"
                target="_blank"
                rel="noreferrer"
              >
                Visit
              </a>
            </li>
            <li>
              <img src="" alt="" width="100%" />
              <h2>
                Neural Analysis and Synthesis: Reconstructing Speech from
                Self-Supervised Representations, NeurIPS 2021.
              </h2>
              <p>
                Hyeong-Seok Choi, Juheon Lee, Wansoo Kim, Jie Hwan Lee, Hoon
                Heo, Kyogu Lee
              </p>
              <a
                href="//arxiv.org/abs/2110.14513"
                target="_blank"
                rel="noreferrer"
              >
                Read
              </a>
            </li>
            <li>
              <img src="" alt="" width="100%" />
              <h2>
                Real-time denoising and dereverberation with tiny recurrent
                U-net, ICASSP 2021.
              </h2>
              <p>
                Hyeong-Seok Choi, Sungjin Park, Jie Hwan Lee, Hoon Heo, Dongsuk
                Jeon, Kyogu Lee
              </p>
              <a
                href="//arxiv.org/pdf/2102.03207.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Read
              </a>
            </li>

            {/* 접히는 부분 */}
            <div className={cn('more', !isMoreContentVisible && 'hidden')}>
              <li>
                <img src="" alt="" width="100%" />
                <h2 className="award">
                  3rd place on the real-time denoising track, Deep Noise
                  Suppression Challenge, ICASSP 2021.
                </h2>
                <a
                  href="//www.microsoft.com/en-us/research/academic-program/deep-noise-suppression-challenge-icassp-2021/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit
                </a>
              </li>
              <li>
                <img src="" alt="" width="100%" />
                <h2>
                  Disentangling timbre and singing style with multi-singer
                  singing synthesis system, ICASSP 2020.
                </h2>
                <p>Juheon Lee, Hyeong-Seok Choi, Junghyun Koo, Kyogu Lee</p>
                <a
                  href="//arxiv.org/pdf/1910.13069.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read
                </a>
              </li>
              <li>
                <img src="" alt="" width="100%" />
                <h2>
                  Adversarially trained end-to-end Korean singing voice
                  synthesis system, Interspeech 2019, Best student paper award.
                </h2>
                <p>
                  Juheon Lee, Hyeong-Seok Choi, Chang-Bin Jeon, Junghyun Koo,
                  Kyogu Lee
                </p>
                <a
                  href="https://arxiv.org/abs/1908.01919"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read
                </a>
              </li>
            </div>
          </ul>

          <button
            className="more-btn"
            onClick={() => setIsMoreContentVisible(!isMoreContentVisible)}
          >
            <img src={moreBtn} alt="" width="34px" />
          </button>
        </article>
      </section>

      {/* Desktop */}
      <section className="pub-n-awards only-pc">
        <article>
          <div className="title no-p">
            <h1>Publications & Awards</h1>
          </div>

          <div className="pna-inner-wrap">
            {/* Left column */}
            <div className="pna-inner pna-img">
              <img src={RealtimeDenoising} alt="" width="100%" />
            </div>

            {/* Right column */}
            <div className="pna-inner">
              <ul>
                <li id="nuvo">
                  <a
                    href="//www.ces.tech/Innovation-Awards/Honorees/2022/Honorees/N/NUVO.aspx"
                    target="_blank"
                    rel="noreferrer"
                    className="no-p"
                  >
                    CES 2022 Innovation Awards Honoree: Software & Mobile Apps,
                    2022.
                  </a>
                </li>
                <li id="neural_analysis">
                  <a
                    href="//arxiv.org/abs/2110.14513"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Neural Analysis and Synthesis: Reconstructing Speech from
                    Self-Supervised Representations, NeurIPS 2021.
                  </a>
                  <p>
                    Hyeong-Seok Choi, Juheon Lee, Wansoo Kim, Jie Hwan Lee, Hoon
                    Heo, Kyogu Lee
                  </p>
                </li>
                <li id="realtime_denoising">
                  <a
                    href="//arxiv.org/pdf/2102.03207.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Real-time denoising and dereverberation with tiny recurrent
                    U-net, ICASSP 2021.
                  </a>
                  <p>
                    Hyeong-Seok Choi, Sungjin Park, Jie Hwan Lee, Hoon Heo,
                    Dongsuk Jeon, Kyogu Lee
                  </p>
                </li>
                <li id="icassp">
                  <a
                    href="//www.microsoft.com/en-us/research/academic-program/deep-noise-suppression-challenge-icassp-2021/"
                    target="_blank"
                    className="no-p"
                    rel="noreferrer"
                  >
                    3rd place on the real-time denoising track, Deep Noise
                    Suppression Challenge, ICASSP 2021.
                  </a>
                </li>
                <li id="disentangling_timbre">
                  <a
                    href="//arxiv.org/pdf/1910.13069.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Disentangling timbre and singing style with multi-singer
                    singing synthesis system, ICASSP 2020.
                  </a>
                  <p>Juheon Lee, Hyeong-Seok Choi, Junghyun Koo, Kyogu Lee</p>
                </li>
                <li id="adversarially_trained">
                  <a
                    href="https://arxiv.org/abs/1908.01919"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Adversarially trained end-to-end Korean singing voice
                    synthesis system, Interspeech 2019, Best student paper
                    award.
                  </a>
                  <p>
                    Juheon Lee, Hyeong-Seok Choi, Chang-Bin Jeon, Junghyun Koo,
                    Kyogu Lee
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default PublicationsAwards;
