import { useEffect } from 'react';

// Scroll to the element with the given id.
const useScrollToElement = (
  elementId: string,
  options?: ScrollIntoViewOptions
) => {
  useEffect(() => {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView(options);
    }
  }, [elementId, options]);
};

export default useScrollToElement;
