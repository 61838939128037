import '../../common/assets/styles/demo.scss';

import { useEffect, useMemo } from 'react';
import useScrollToTop from '../../common/hook/useScrollToTop';
import Footer from '../../common/v3/Footer';
import HashElementScroller from '../../common/HashElementScroller';
import { getItem } from '../../common/localStorage';
import { DEMO_LICENSE_AGREED_KEY } from './AgreementPage';
import { useLocation, useNavigate } from 'react-router-dom';
import useScrollToElement from '../../common/hook/useScrollToElement';

import Logo from '../../common/assets/logos/logo-supertone-ai.svg';
import PoweredBy from '../../common/assets/images/demo-pages/poweredby-A.svg';

const DemoPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isAgreed = useMemo(() => {
    const agreed = getItem(DEMO_LICENSE_AGREED_KEY);
    return agreed === 'true';
  }, []);

  // Add 'demo' class to the body.
  useEffect(() => {
    document.body.classList.add('demo');

    return () => {
      document.body.classList.remove('demo');
    };
  }, []);

  // Scroll to top when the page is loaded.
  useScrollToTop();

  useEffect(() => {
    // Move to agreement page if not agreed.
    if (!isAgreed) {
      navigate('/demo/agreement' + location.hash, {
        replace: true,
      });

      return;
    }
  }, [isAgreed, navigate, location.hash]);

  // Get hash element id from the url.
  const hashElementId = useMemo(() => {
    return location.hash?.substring(1);
  }, [location.hash]);

  // Scroll to the element with the given id.
  useScrollToElement(hashElementId);

  return (
    <>
      {/* Header */}
      <header id="header">
        <img src={Logo} alt="" className="logo-supertone" />
        <div className="logo-video">DEMO</div>
        <img src={PoweredBy} alt="" className="logo-poweredby" />
      </header>

      {/* Intro */}
      <section id="section-intro">
        <article>
          <div className="intro-wrap">
            <div className="ko">
              수퍼톤(SUPERTONE)은 김광석, 터틀맨 복원 등 수차례의 방송 콘텐츠를
              통해 AI 음성합성 기술이 엔터테인먼트 시장에 적용될 수 있음을 세계
              최초로 입증했고, 현재 업계 내에서 유일하게 자연스러운 연기 및 가창
              콘텐츠를 제작할 수 있습니다. 저희는 이번 BIFF를 통해 무한한
              목소리의 창조, 나이와 성별 등의 자연스러운 변환, 손쉬운 대사 교체,
              연기 영역의 확장까지, 기존에는 불가능하리라 여겼던 다양한 AI 기반
              제작 사례를 공유하고 혁신적인 기술을 선보이려 합니다.
            </div>
            <div className="en">
              SUPERTONE is an AI voice technology that enables creators to
              produce immersive content with ease. SUPERTONE’s active presence
              in the music and digital human industry has been a successful
              milestone, and we hope to expand and present our breathtaking
              technology to the film world at BIFF. We, SUPERTONE, are the
              world’s most innovative audio technology creating and providing
              solutions for what has been an impossible chore for creators in
              the past.
            </div>
          </div>
        </article>
      </section>

      <article id="rap">
        <div className="title-demo">
          <a href="#rap">Need professional singer?</a>
        </div>
        <div className="title-demo-sub">
          캐릭터, 배우의 목소리로 노래도 랩도 할 수 있습니다.
        </div>

        <div className="video">
          {/* 랩 */}
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/confidential/demo-cvc-rap-confidential.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="mickeymouse">
        <div className="title-demo">
          <a href="#mickeymouse">Need deceased actor to make sequel?</a>
        </div>
        <div className="title-demo-sub">
          원작 캐릭터, 배우의 목소리로 지속적인 콘텐츠 제작이 가능합니다.
        </div>
        <div className="video">
          {/* 고인 목소리 복원 - 미키마우스 */}
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/confidential/demo-cvc-mickey-mouse-confidential.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="irishman">
        <div className="title-demo">
          <a href="#irishman">Need younger or older voice?</a>
        </div>
        <div className="title-demo-sub">
          연기톤과 캐릭터성을 유지하면서 목소리의 나이를 조절할 수 있고 성별도
          바꿀 수 있습니다.
        </div>
        <div className="video">
          {/* 목소리 디에이징 - 아이리시 */}
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/confidential/demo-deage-irish-confidential.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="tomcruise">
        <div className="title-demo">
          <a href="#tomcruise">Enhancing dialogue without ADR</a>
        </div>
        <div className="title-demo-sub">
          후시 녹음 없이 잡음과 울림을 제거해서 또렸한 목소리를 들리게 할 수
          있습니다.
        </div>
        <div className="video">
          {/* 인터뷰 - 탐크루즈 */}
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/confidential/demo-denoise-tom-cruise-confidential.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="pulpfiction">
        <div className="title-demo">
          <a href="#pulpfiction">Changing dialogue without ADR</a>
        </div>
        <div className="title-demo-sub">
          후시 녹음 없이도 대사를 수정할 수 있습니다.
        </div>
        <div className="video">
          {/* 펄프픽션 */}
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/confidential/demo-change-dialogue-pulp-fiction-confidential.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="starwars">
        <div className="title-demo">
          <a href="#starwars">Make whole new dialogue without ADR</a>
        </div>
        <div className="title-demo-sub">
          후시 녹음 없이 새로운 대사 또는 나래이션을 만들 수 있습니다.
        </div>
        <div className="video">
          {/* 스타워즈 */}
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/confidential/demo-new-dialogue-starwars-confidential.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="lionking">
        <div className="title-demo">
          <a href="#lionking">Unimaginable creature voice</a>
        </div>
        <div className="title-demo-sub">
          상상하지 못했던 새로운 목소리를 창의적으로 만들 수 있습니다.
        </div>
        <div className="video">
          {/* 라이온킹 */}
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/confidential/demo-creature-voice-lion-king-confidential.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="davidbeckham">
        <div className="title-demo">
          <a href="#davidbeckham">Face matching voice recommendation</a>
        </div>
        <div className="title-demo-sub">
          캐릭터에 어울리는 목소리를 추천하고 제작할 수 있습니다.
        </div>
        <div className="video">
          {/* 베컴 */}
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/confidential/demo-face-to-voice-beckham-confidential.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="agegender">
        <div className="title-demo">
          <a href="#agegender">
            Bring you Countless Complete Digital Character
          </a>
        </div>
        <div className="title-demo-sub">
          창작자가 생각하는 목소리를 무한대로 생성하고 디자인할 수 있습니다.
        </div>
        <div className="video">
          {/* Smooth Transition Voice */}
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/demo-seamless-voice-transition.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="squidgame">
        <div className="title-demo">
          <a href="#squidgame">Immersive multi-lingual voice dubbing</a>
        </div>
        <div className="title-demo-sub">
          연기톤과 캐릭터성을 유지하면서 몰입도 높은 다국어 더빙이 가능합니다.
        </div>
        <div className="video">
          {/* 다국어 더빙 */}
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/confidential/demo-cvc-squid-game-confidential.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="flymetoothemoon">
        <div className="title-demo">
          <a href="#flymetoothemoon">Elvis’ Virtual Concert</a>
        </div>
        <div className="title-demo-sub">
          We can imagine Elvis Presley holding a concert in the Fortnite world
          with a song he’s never sung.
        </div>
        <div className="video">
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/demo-cvc-elvis-presley.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="arcane">
        <div className="title-demo">
          <a href="#arcane">Arcane Opening Theme</a>
        </div>
        <div className="title-demo-sub">Vi/Ekko</div>
        <div className="dbpq-box">
          <iframe
            title="arcane-opening-theme"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/enemy-before-4.mp4,https://d4vi5jlucxn3x.cloudfront.net/enemy-after-2.mp3&amp;button=Imagine%20Dragons%20×%20J.I.D,Vi%20×%20Ekko"
          ></iframe>
        </div>
      </article>

      <article id="supertts">
        <div className="title-demo">
          <a href="#supertts">Super TTS</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="video">
          <video
            src="https://d4vi5jlucxn3x.cloudfront.net/supertone+official+website/demo-tts-arcane.mp4#t=0.001"
            controls
            playsInline
          ></video>
        </div>
      </article>

      <article id="lolrtvc">
        <div className="title-demo">
          <a href="#lolrtvc">RT Cloned Voice Chat</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="rt-cloned-voice-chat"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/rtvc-riot-dbpq-original-2.mp4,https://d4vi5jlucxn3x.cloudfront.net/rtvc-riot-dbpq-cvc.mp3&amp;button=Original,RTVC"
          ></iframe>
        </div>
      </article>

      <article id="silco">
        <div className="title-demo">
          <a href="#silco">Multilingual Voice Actor: Silco</a>
        </div>
        <section>
          <div className="title-demo-section">Original Voice</div>
          <div className="dbpq-box">
            <iframe
              title="multilingual-original"
              src="https://supertone.studio/dbpq-embed?src=//d4vi5jlucxn3x.cloudfront.net/arcane-silco-en.mp4,//d4vi5jlucxn3x.cloudfront.net/arcane-silco-fr-original.mp3,//d4vi5jlucxn3x.cloudfront.net/arcane-silco-jp-original.mp3,//d4vi5jlucxn3x.cloudfront.net/arcane-silco-kr-original.mp3&amp;button=🇺🇸,🇫🇷,🇯🇵,🇰🇷"
            ></iframe>
          </div>
        </section>
        <section>
          <div className="title-demo-section">Cloned Voice</div>
          <div className="dbpq-box">
            <iframe
              title="multilingual-cloned"
              src="https://supertone.studio/dbpq-embed?src=//d4vi5jlucxn3x.cloudfront.net/arcane-silco-en.mp4,//d4vi5jlucxn3x.cloudfront.net/arcane-silco-fr.mp3,//d4vi5jlucxn3x.cloudfront.net/arcane-silco-jp.mp3,//d4vi5jlucxn3x.cloudfront.net/arcane-silco-kr-2.mp3&amp;button=🇺🇸,🇫🇷,🇯🇵,🇰🇷"
            ></iframe>
          </div>
        </section>
      </article>

      <article id="albumplanning">
        <div className="title-demo">
          <a href="#albumplanning">Concept Album Planning</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="concept-album-planning"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/1%EB%B9%8C%EB%A6%AC%EC%97%AC_M.mov,https://d4vi5jlucxn3x.cloudfront.net/2%EB%B9%8C%EB%A6%AC%EB%82%A8_M.wav,https://d4vi5jlucxn3x.cloudfront.net/3%EB%B9%8C%EB%A6%AC%EB%82%A8%EC%97%AC_M.wav&amp;button=%EC%97%AC,%EB%82%A8,%EB%93%80%EC%97%A3"
          ></iframe>
        </div>
      </article>

      <article id="soundmaking">
        <div className="title-demo">
          <a href="#soundmaking">Signature Sound Making</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="signature-sound-making"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/Drum%20Loop.mp4,https://d4vi5jlucxn3x.cloudfront.net/Tom%20Hiddleston%20Drum.wav,https://d4vi5jlucxn3x.cloudfront.net/Bass%20Loop.wav,https://d4vi5jlucxn3x.cloudfront.net/Robert%20D%20Jr%20Bass.wav,https://d4vi5jlucxn3x.cloudfront.net/Synth%20Loop.wav,https://d4vi5jlucxn3x.cloudfront.net/Ed%20Sheeran%20Synth.wav&amp;button=Drum%20Loop,Tom%20Hiddleston%20Drum,Bass%20Loop,Robert%20D%20Jr%20Bass,Synth%20Loop,Ed%20Sheeran%20Synth"
          ></iframe>
        </div>
      </article>

      <article id="cleanvocal">
        <div className="title-demo">
          <a href="#cleanvocal">Clean Vocal</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="clean-vocal"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_%EC%9B%90%EB%B3%B8.mp4,https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_MSS.mp3&amp;button=%EC%9B%90%EB%B3%B8,De-Reverb"
          ></iframe>
        </div>
      </article>

      <article id="pitchchange">
        <div className="title-demo">
          <a href="#pitchchange">Pitch Change</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="pitch-change"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_Pitch_%EC%9B%90%EB%B3%B8.mp4,https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_Pitch_-12_Melodyne.mp3,https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_Pitch_-12_Supertone.mp3,https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_Pitch_6_Melodyne.mp3,https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_Pitch_6_Supertone.mp3&amp;button=Pitch_%EC%9B%90%EB%B3%B8,-12_Melodyne,-12_Supertone,6_Melodyne,6_Supertone"
          ></iframe>
        </div>
      </article>

      <article id="genderagechange">
        <div className="title-demo">
          <a href="#genderagechange">Gender-Age Change</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="gender-age-change"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_Mss_%EC%9B%90%EB%B3%B8.mp4,https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_Gender_Man.mp3,https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_Age_Young.mp3,https://d4vi5jlucxn3x.cloudfront.net/%EC%95%84%EB%A6%AC%EC%95%84%EB%82%98_Age_Old.mp3&amp;button=Female,Male,Young,Old"
          ></iframe>
        </div>
      </article>

      <article id="melodychange">
        <div className="title-demo">
          <a href="#melodychange">Melody Change</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="melody-change"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/1%EB%A0%88%EC%9D%B4%EC%B0%B0%EC%8A%A4_%EC%9B%90%EB%B3%B8.mp4,https://d4vi5jlucxn3x.cloudfront.net/2%EB%A0%88%EC%9D%B4%EC%B0%B0%EC%8A%A4_%EC%95%A0%EB%93%9C%EB%A6%BD.mp4&amp;button=%EC%9B%90%EB%B3%B8,%EC%95%A0%EB%93%9C%EB%A6%BD"
          ></iframe>
        </div>
      </article>

      <article id="smoothvibration">
        <div className="title-demo">
          <a href="#smoothvibration">Smooth Vibration</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="smoothvibration"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/%EC%9B%90%EB%B3%B8.mp4,https://d4vi5jlucxn3x.cloudfront.net/v1.mp4,https://d4vi5jlucxn3x.cloudfront.net/v3.mp4&amp;button=%EC%9B%90%EB%B3%B8,%EB%B9%84%EB%B8%8C%EB%9D%BC%ED%86%A01,%EB%B9%84%EB%B8%8C%EB%9D%BC%ED%86%A02"
          ></iframe>
        </div>
      </article>

      <article id="lyricsdiction">
        <div className="title-demo">
          <a href="#lyricsdiction">Edit Lyrics and Diction</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="lyrics-diction"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/%EA%B0%80%EC%82%AC%EC%88%98%EC%A0%95_pulpfiction%20M.mp4&amp;button=%EA%B0%80%EC%82%AC%20%EC%88%98%EC%A0%95"
          ></iframe>
        </div>
      </article>

      <article id="richchorus">
        <div className="title-demo">
          <a href="#richchorus">Make Rich Chorus</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="rich-chorus"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/Ed%20Original%20M.mp4,https://d4vi5jlucxn3x.cloudfront.net/M_Original%20with%20Cho-St.wav,https://d4vi5jlucxn3x.cloudfront.net/M_Cho%20Uni%20GenAge%20Change-St.wav,https://d4vi5jlucxn3x.cloudfront.net/M_Cho%203semi%20up-St.wav,https://d4vi5jlucxn3x.cloudfront.net/M_Cho%201Oct%20down-St.wav&amp;button=Original%20Song,Original%20Song%20with%20Cho,Cho%20Uni%20GenAge%20Change,Cho%203semi%20up,Cho%201Oct%20down"
          ></iframe>
        </div>
      </article>

      <article id="genre">
        <div className="title-demo">
          <a href="#genre">Sing Any Style of Genre</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="genre"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/1%EC%95%84%EC%9D%B4%EC%9C%A0%EC%9B%90%EB%B3%B8_M4.mov,https://d4vi5jlucxn3x.cloudfront.net/2%EC%95%84%EC%9D%B4%EC%9C%A0%ED%8A%B8%EB%A1%9C%ED%8A%B8_M4.wav&amp;button=%EC%9B%90%EB%B3%B8,%ED%8A%B8%EB%A1%9C%ED%8A%B8"
          ></iframe>
        </div>
      </article>

      <article id="multilingualsinging">
        <div className="title-demo">
          <a href="#multilingualsinging">Professional Multi-Lingual Singing</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="multilingual-singing"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/1%EB%A0%9B%EC%9E%87%EA%B3%A0_%EB%AF%B8%EA%B5%AD.mp4,https://d4vi5jlucxn3x.cloudfront.net/2%EB%A0%9B%EC%9E%87%EA%B3%A0_%EC%9D%BC%EB%B3%B8.mp3,https://d4vi5jlucxn3x.cloudfront.net/3%EB%A0%9B%EC%9E%87%EA%B3%A0_%EC%A4%91%EA%B5%AD.mp3&amp;button=1%EB%A0%9B%EC%9E%87%EA%B3%A0_%EB%AF%B8%EA%B5%AD,2%EB%A0%9B%EC%9E%87%EA%B3%A0_%EC%9D%BC%EB%B3%B8,3%EB%A0%9B%EC%9E%87%EA%B3%A0_%EC%A4%91%EA%B5%AD"
          ></iframe>
        </div>
      </article>

      <article id="multilingualcontent">
        <div className="title-demo">
          <a href="#multilingualcontent">
            Multi-Lingual Content for Social Media
          </a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="multilingual-content"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/1%ED%94%84%EB%A0%88%EB%94%94_%EB%AF%B8%EA%B5%AD.mp4,https://d4vi5jlucxn3x.cloudfront.net/2%ED%94%84%EB%A0%88%EB%94%94_%EC%A4%91%EA%B5%AD.wav,https://d4vi5jlucxn3x.cloudfront.net/3%ED%94%84%EB%A0%88%EB%94%94_%EB%8F%85%EC%9D%BC.wav,https://d4vi5jlucxn3x.cloudfront.net/4%ED%94%84%EB%A0%88%EB%94%94_%EC%8A%A4%ED%8E%98%EC%9D%B8.wav,https://d4vi5jlucxn3x.cloudfront.net/5%ED%94%84%EB%A0%88%EB%94%94_%ED%84%B0%ED%82%A4.wav&amp;button=%EB%AF%B8%EA%B5%AD,%EC%A4%91%EA%B5%AD,%EB%8F%85%EC%9D%BC,%EC%8A%A4%ED%8E%98%EC%9D%B8,%ED%84%B0%ED%82%A4"
          ></iframe>
        </div>
      </article>

      <article id="fandombiz">
        <div className="title-demo">
          <a href="#fandombiz">Fandom Biz : Audio Goods</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="fandom-biz"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/BD%20song.mp4,https://d4vi5jlucxn3x.cloudfront.net/M_BDS_%EB%B0%A9%EC%8B%9C%ED%98%81-St.wav,https://d4vi5jlucxn3x.cloudfront.net/M_BDS_%EC%9D%B4%EC%8A%B9%EB%B3%B5-St.wav&amp;button=%ED%95%98%EC%9D%B4%EB%B8%8C,%EB%B0%A9%EC%8B%9C%ED%98%81,%EC%9D%B4%EC%8A%B9%EB%B3%B5"
          ></iframe>
        </div>
      </article>

      <article id="ravenmusical">
        <div className="title-demo">
          <a href="#ravenmusical">Raven : Live Performance in Musical</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="raven-musical"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/1Jekyll%26Htde_Confrontation_%EC%9B%90%EB%B3%B8_%EC%88%98%EC%A0%95.mp4,https://d4vi5jlucxn3x.cloudfront.net/2Jekyll%26Htde_Confrontation_Hyde.mp3,https://d4vi5jlucxn3x.cloudfront.net/3Jekyll%26Htde_Confrontation_Jekyll%26Hyde.mp3&amp;button=%EC%A7%80%ED%82%AC,%ED%95%98%EC%9D%B4%EB%93%9C,%EC%A7%80%ED%82%AC%EC%95%A4%ED%95%98%EC%9D%B4%EB%93%9C"
          ></iframe>
        </div>
      </article>

      <article id="mss">
        <div className="title-demo">
          <a href="#mss">Music Source Separation</a>
        </div>
        <div className="title-demo-sub"></div>
        <div className="dbpq-box">
          <iframe
            title="mss"
            src="https://supertone.studio/dbpq-embed?src=https://d4vi5jlucxn3x.cloudfront.net/1MSS_Rolling_AR.mp4,https://d4vi5jlucxn3x.cloudfront.net/2Adele_Rolling_mss_Inst.mp3,https://d4vi5jlucxn3x.cloudfront.net/3Adele_Rolling_mss_Voice.mp3&amp;button=AR,Inst,Voice"
          ></iframe>
        </div>
      </article>

      {/* Footer */}
      <Footer />

      <HashElementScroller />
    </>
  );
};

export default DemoPage;
