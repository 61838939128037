import { useEffect, useState } from 'react';
import MainBackground0 from '../../../common/assets/images/main-background-0.png';
import MainBackground1 from '../../../common/assets/images/main-background-1.png';
import MainBackground2 from '../../../common/assets/images/main-background-2.png';
import MainBackground3 from '../../../common/assets/images/main-background-3.png';
import MainBackground4 from '../../../common/assets/images/main-background-4.png';
import MainBackground5 from '../../../common/assets/images/main-background-5.png';
import MainBackground6 from '../../../common/assets/images/main-background-6.png';
import MainBackground7 from '../../../common/assets/images/main-background-7.png';
import MainBackground8 from '../../../common/assets/images/main-background-8.png';
import MainBackground9 from '../../../common/assets/images/main-background-9.png';
import MainBackground10 from '../../../common/assets/images/main-background-10.png';
import MainBackground11 from '../../../common/assets/images/main-background-11.png';

const mainContent: Record<number, any> = {
  0: ['Artist', undefined, '#91ca3e', '#a0c6e0'],
  1: ['Producer', undefined, '#a393f5', '#ff9933'],
  2: ['Film Makers', undefined, '#ff7a48', '#34ade0'],
  3: ['Screenwriters', undefined, '#f1e104', '#ff5a68'],
  4: ['Actors', undefined, '#ff9933', '#00aabc'],
  5: ['Creators', undefined, '#ffffff', '#f1e204'],
  6: ['Brand Designers', undefined, '#ff9933', '#f1acb2'],
  7: ['Youtubers', undefined, '#91ca3e', '#a393f5'],
  8: ['Character', 'Designers', '#f1acb2', '#016e80'],
  9: ['Audio Book', 'Publishers', '#34ade0', '#ff7a48'],
  10: ['Voice Actors', undefined, '#a393f5', '#91ca3e'],
  11: ['Creators', undefined, '#ffffff', '#f1e204'],
};

const mainBackgrounds = [
  MainBackground0,
  MainBackground1,
  MainBackground2,
  MainBackground3,
  MainBackground4,
  MainBackground5,
  MainBackground6,
  MainBackground7,
  MainBackground8,
  MainBackground9,
  MainBackground10,
  MainBackground11,
];

const Main = () => {
  const [mainContentIndex, setMainContentIndex] = useState<number>(0);

  useEffect(() => {
    const handle = window.setInterval(
      () => setMainContentIndex((prev) => (prev + 1) % 12),
      3000
    );

    return () => window.clearInterval(handle);
  }, []);

  const spanStyle = {
    background: mainContent[mainContentIndex][2],
  };

  return (
    <section
      className="main"
      style={{
        background: mainContent[mainContentIndex][3],
      }}
      onClick={() => setMainContentIndex((prev) => (prev + 1) % 12)}
    >
      <article>
        <h1>
          <span style={spanStyle}>Intelligent</span>
          <br />
          <span style={spanStyle}>Audio</span>
          <br />
          <span style={spanStyle}>for</span>
          <br />
          <span className="main-word-1" style={spanStyle}>
            {mainContent[mainContentIndex][0]}
          </span>
          <br />
          <span
            className="main-word-2"
            style={{
              ...spanStyle,
              background:
                mainContent[mainContentIndex][1] === undefined
                  ? 'transparent'
                  : mainContent[mainContentIndex][2],
            }}
          >
            {mainContent[mainContentIndex][1]}
          </span>
        </h1>
      </article>
      <div
        className="main-bg"
        style={{
          background: `url(${mainBackgrounds[mainContentIndex]}) center/cover no-repeat`,
        }}
      ></div>
    </section>
  );
};

export default Main;
