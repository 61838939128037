const People = () => {
  return (
    <section className="people">
      <article>
        <h1>
          When
          <br />
          Supertone
          <br />
          creates,
          <br />
          the world
          <br />
          listens.
        </h1>
      </article>
    </section>
  );
};

export default People;
