import useScrollToTop from '../../common/hook/useScrollToTop';
import Media from './partial/Media';
import News from './partial/News';

const MediaPage = () => {
  useScrollToTop();

  return (
    <>
      {/* Media */}
      <Media />

      {/* News */}
      <News />
    </>
  );
};

export default MediaPage;
